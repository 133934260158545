<template>
  <div class="fb-new">
    <div class="top">
      <p class="title">
        {{ typeAction(type) }}
        <span>卡号：{{ card }}</span>
      </p>
      <textarea
        v-model="question"
        placeholder="请详细描述问题,可附图说明,最多支持150字"
        maxlength="150"
      />
      <div class="upload">
        <img
          :src="item"
          alt=""
          v-for="(item, index) in imgList"
          :key="index"
          @click="seeImgFn(item, index + 1)"
        />
        <input
          v-if="imgList.length < 3"
          type="file"
          class="img"
          @change="uploadImg"
          :disabled="Boolean(fileList.length >= 3)"
        />
      </div>
    </div>
    <div class="mid" v-if="typeItem.isImei == 1">
      <span class="tip">可百度查询怎么获取设备IMEI</span>
    </div>
    <div class="contact">
      <p class="title">联系方式</p>
      <van-cell>
        <van-radio-group
          v-model="radio"
          direction="horizontal"
          @change="radioFn"
        >
          <van-radio name="0">QQ</van-radio>
          <van-radio name="1">微信</van-radio>
          <van-radio name="2">手机号</van-radio>
        </van-radio-group>
      </van-cell>
      <input
        :type="contactJudge.type"
        v-model="contact"
        onpaste="return false"
        ondragenter="return false"
        @input="judge"
        placeholder="请输入手机/QQ/微信,便于我们与您沟通"
      />
    </div>
    <button
      :disabled="!Boolean(contact) || !Boolean(question)"
      class="submit"
      @click="submit"
    >
      提交
    </button>
    <!-- 预览弹框 -->
    <van-popup v-model:show="seeImgShow" round>
      <div class="previewBox">
        <div class="previewImg">
          <img :src="seeImg" alt="logo" />
        </div>
        <div class="previewButton" @click="deleteImg">删除</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// 需要哪些模块按需引入
import { toRefs, reactive, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { descSubmit } from "_API_/api.services";
import { fileToImgFn } from "@/utils/utils";
import { getStore } from "@/utils/store";
export default {
  setup() {
    const state = reactive({
      iccid: "", //iccid卡号
      card: "", //卡号
      radio: "1", //radio组件默认选中值
      type: "", //填写类型
      typeItem: {}, //类型项目
      contact: "", //用户输入的手机/QQ/微信信息
      question: "", //用户输入的问题信息
      imgList: [], //图片列表
      fileList: [], //图片路径列表
      seeImg: "", //预览的图片
      seeImgIndex: "", //预览的图片下标
      seeImgShow: false, //预览的图片弹框展示
      contacts_type: 1, //输入类型选取
      contactJudge: {
        length: 11,
        type: "number"
      }, //输入类型
      imei: "" //imei卡
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      //信息源填写选取
      radioFn(val) {
        state.contact = "";
        if (val == 0) {
          state.contactJudge.length = 11;
          state.contactJudge.type = "number";
        }
        if (val == 1) {
          state.contactJudge.length = 30;
          state.contactJudge.type = "text";
        }
        if (val == 2) {
          state.contactJudge.length = 11;
          state.contactJudge.type = "number";
        }
      },
      //输入框信息操作
      judge() {
        switch (state.contacts_type) {
          case 0:
            state.contact;
            var temp = "";
            for (var i = 0; i < state.contact.length; i++) {
              if (
                state.contact.charCodeAt(i) > 0 &&
                state.contact.charCodeAt(i) < 255
              ) {
                temp += state.contact.charAt(i);
                state.contact = temp;
              }
            }
            break;
          case 1:
            state.contact = state.contact.slice(0, 15);
            break;
          case 2:
            state.contact = state.contact.slice(0, 11);
            break;
          default:
            break;
        }
      },
      //提交
      submit() {
        if (state.typeItem.isImei == 1 && !state.imei) {
          tool.toast(
            {
              msg: "请填写IMEI"
            },
            () => {}
          );
          return;
        }
        let conf = new FormData(); //创建一个新的 FormData 对象。
        //向 FormData 中添加新的属性值
        conf.append("iccid", state.iccid);
        conf.append("contacts", state.contact);
        conf.append("description", state.question);
        conf.append("type", state.type);
        conf.append("contacts_type", state.contacts_type);
        if (state.imei) {
          conf.append("imei", state.imei);
        }
        state.fileList.forEach(item => {
          conf.append("files", item);
        });
        descSubmit(conf).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast(
              {
                msg: "反馈提交成功"
              },
              () => {
                router.push({
                  name: "fb-list"
                });
              }
            );
            return;
          }
          tool.toast(
            {
              msg: res.msg
            },
            () => {}
          );
        });
      },
      //上传图片
      uploadImg(file) {
        var file = event.target.files[0];
        if (!Boolean(file)) {
          return;
        }
        if (file.type != "image/png" && file.type != "image/jpeg") {
          tool.toast(
            {
              msg: "只支持上传png和jpeg图片！"
            },
            () => {}
          );
          return;
        }
        if (file.size / 1024 > 2048) {
          tool.toast(
            {
              msg: "图片最大不得超过2MB"
            },
            () => {}
          );
          return;
        }
        state.fileList.push(file);
        ctx.fileToImg(file);
        event.target.value = "";
      },
      async fileToImg(file) {
        let imgUrl = await fileToImgFn(file); // 图片转换处理,图片的 base64 格式, 可以直接当成 img 的 src 属性值
        state.imgList.push(imgUrl);
      },
      // 预览图片
      seeImgFn(item, index = 0) {
        state.seeImgShow = true;
        state.seeImg = item;
        state.seeImgIndex = index;
      },
      // 删除图片
      deleteImg() {
        state.imgList.splice(state.seeImgIndex - 1, 1);
        state.fileList.splice(state.seeImgIndex - 1, 1);
        state.seeImgShow = false;
      },
      // 反馈类型
      typeAction(type) {
        if (!type) {
          return;
        }
        let data = state.typeList.filter(item => {
          return item.id == type;
        })[0];
        return data.title;
      }
    };
    onMounted(() => {
      //获取SessionStorage存储的类型列表
      state.typeList = getStore({ name: "typeList" });
      //获取工单类型
      state.type = route.query.type;
      //获取用户iccid  工单查询页进入 或  公众号查询页进入
      state.iccid =
        store.state.user.queryIccid || store.state.user.userInfo.iccid;
      //获取用户卡号  工单查询页进入 或  公众号查询页进入
      state.card =
        store.state.user.queryVirtualId ||
        store.state.user.userInfo.virtualId ||
        store.state.user.queryIccid ||
        store.state.user.userInfo.iccid;
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.fb-new {
  height: 1000px;
  .top {
    height: 406px;
    background: #fff;
    margin-bottom: 30px;
    .title {
      font-weight: bold;
      font-size: 20px;
      padding: 10px 10px;
      span {
        color: #c0c0c0;
        font-weight: normal;
      }
    }
    textarea {
      border: none;
      width: 722px;
      height: 200px;
      font-size: 30px;
      padding: 10px;
      margin-top: 30px;
    }
    div {
      padding: 10px 20px;
      input.img {
        width: 70px;
        height: 70px;
        padding-bottom: 44px;
        position: relative;
        vertical-align: middle;
        overflow: hidden;
      }
      input.img:before {
        content: "";
        width: 60px;
        height: 60px;
        display: inline-block;
        vertical-align: middle;
        background: url(../../assets/image/feedBack/add.png);
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-color: #fff;
        border: 5px solid #fff;
      }
      img {
        width: 70px;
        height: 70px;
      }
    }
  }
  .contact {
    background: #fff;
    height: 230px;
    p.title {
      font-weight: bold;
      font-size: 20px;
      padding: 20px 20px;
    }
    input {
      border: none;
      margin-top: 10px;
      width: 580px;
      margin-left: 40px;
    }
    .selector {
      padding-left: 3%;
    }
  }
  .mid {
    background: #fff;
    margin-bottom: 20px;
    span,
    input {
      font-size: 20px;
    }
    .tip {
      margin-left: 2%;
      font-size: 20px;
      color: #969696;
    }
  }
}
.submit {
  @include background_color("background_color1");
  width: 96%;
  height: 70px;
  font-size: 20px;
  color: #fff;
  display: block;
  text-align: center;
  line-height: 20px;
  border: none;
  border-radius: 10px;
  margin: 40px auto;
}
.submit[disabled] {
  @include background_color("background_color2");
}
.previewBox {
  display: flex;
  flex-direction: column;
  .previewImg {
    img {
      width: 268px;
      height: 168px;
    }
  }
  .previewButton {
    text-align: center;
    color: #ffffff;
    background: #ff4937;
  }
}
.pop {
  text-align: center;
  .img {
    max-width: 90%;
    max-height: 80px;
    margin-bottom: 10px;
  }
}
</style>
